<template>
	<div>
		<div class="ser-form ser-form-centered">
			<div class="form-item-content">
				<Input clearable class="margin-right" v-model="serData.name" placeholder="输入名称搜索"
					style="width: 200px" />
				<Button type="primary" @click="init()" class="margin-right">搜索</Button>
				<Button type="primary" icon="md-add" @click="openOperation(0)">新增设备操作</Button>
			</div>
		</div>
		<div class="table-list">
			<Table :columns="columns" :data="data" :loading="loading">
				<template slot-scope="{row}" slot="operation">
					<Button @click="openOperation(1,row)" style="margin-right: 8px;">编辑</Button>
					<Button @click="del(row.id)">删除</Button>
				</template>
				<template slot-scope="{row}" slot="type">
					<div>{{ type_array[row.type] }}</div>
				</template>
				<template slot-scope="{row}" slot="default_type">
					<div>{{ value_type[row.default_type] }}</div>
				</template>
				<template slot-scope="{row}" slot="display">
					<div>{{ display_type[row.display] }}</div>
				</template>
			</Table>
		</div>
		<div class="ser-page">
			<Page class-name="page-r" :total="total" :page-size="serData.pageSize" :page-size-opts="[15,30,50]"
				@on-change="changePage" @on-page-size-change="changePageSize" show-total show-sizer></Page>
		</div>

		<Modal v-model="open_device_op" :title="edit_data?'编辑':'新增'">
			<Form :model="edit_data" :label-width="100">
				<FormItem label="名称">
					<Input v-model="edit_data.name" placeholder="请输入名称"></Input>
				</FormItem>
				<FormItem label="参数名">
					<Input v-model="edit_data.value_name" placeholder="请输入参数名称"></Input>
				</FormItem>
				<FormItem label="设置类型">
					<Select v-model="edit_data.type">
						<Option v-for="(item,index) in type_array" :value="index" :key="index">{{ item }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="参数类型">
					<Select v-model="edit_data.default_type">
						<Option v-for="(item,index) in value_type" :value="index" :key="index">{{ item }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="参数默认值">
					<Input v-model="edit_data.default" :disabled='edit_data.default_type == 3 || edit_data.default_type == 0 ? true : false' placeholder="请输入默认值"></Input>
				</FormItem>
				<FormItem label="显示">
					<Select v-model="edit_data.display">
						<Option v-for="(item,index) in display_type" :value="index" :key="index">{{ item }}
						</Option>
					</Select>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="primary" @click="edit_type">保存</Button>
				<Button @click="open_device_op=false">取消</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				total: 0,
				serData: {
					page: 1,
					pageSize: 15,
					name: null,
				},
				columns: [{
					title: 'ID',
					key: 'id'
				}, {
					title: '名称',
					key: 'name'
				}, {
					title: '参数名',
					key: 'value_name'
				}, {
					title: '设置类型',
					slot: 'type'
				}, {
					title: '参数类型',
					slot: 'default_type'
				}, {
					title: '参数默认值',
					key: 'default'
				}, {
					title: '显示',
					slot: 'display'
				}, {
					title: '操作',
					slot: 'operation',
					minWidth: 100
				}],
				data: [],
				loading: false,
				model_data: [],
				type_data: [],
				open_device_op: false,
				edit_data: {
					id: null,
					name: null,
					value_name: null,
					type: null,
					default_type: null,
					default: null,
					display:null
				},
				type_array: ['其他设置', '间隔设置（分钟）', '阈值设置', '报警设置', '紧急联系人设置', '联系人设置', '物联卡白名单', '闹钟设置', '计步时间段设置'],
				value_type: ['数组', '整数', '开关','电话号码','小数','选择'],
				display_type: ['全部','用户端','商家端','不显示'],
			}
		},
		methods: {
			init() {
				this.loading = true
				var _this = this
				this.requestApi('/adm/get_device_op_all', {
					data: this.serData
				}).then(function(res) {
					_this.loading = false
					if (res.status == 200) {
						_this.data = res.data.data
						_this.total = res.data.total
					}
				})
			},
			openOperation(e, value = 0) {
				if (e) {
					var obj = JSON.parse(JSON.stringify(value))
					this.edit_data = obj
				} else {
					this.edit_data.id = null
					this.edit_data.name = null
					this.edit_data.value_name = null
					this.edit_data.type = null
					this.edit_data.default_type = null
					this.edit_data.default = null
					this.edit_data.display = null
				}
				this.open_device_op = true
			},
			changePage(page) {
				this.serData.page = page;
				this.init()
			},
			changePageSize(size) {
				this.serData.page = 1;
				this.serData.pageSize = size;
				this.init()
			},
			//编辑
			edit_type() {
				var _this = this
				this.requestApi('/adm/edit_op_all', {
					data: this.edit_data
				}).then(function(res) {
					if (res.status == 200) {
						_this.alertSucc(res.msg)
						_this.init()
						_this.open_device_op = false
					} else {
						_this.alertErr(res.msg)
					}
				})
			},
			//删除
			del(id) {
				var _this = this
				this.$Modal.confirm({
					title: '提示',
					content: '确定删除该设备参数吗？',
					onOk: (res) => {
						this.requestApi('/adm/del_op_all', {
							id: id
						}).then(function(res) {
							if (res.status == 200) {
								_this.alertSucc(res.msg)
								_this.init()
							} else {
								_this.alertErr(res.msg)
							}
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ser-form-centered {
		display: flex;
		align-items: center;
	}

	.margin-right {
		margin-right: 10px;
	}
</style>